<!-- 商家详情 -->
<template>
    <div class="business f-wrap">
        <div class="business-briefIntroduction">
            <div class="business-backImg">
                <img v-lazy="userInfo.background" />
            </div>
            <div class="business-info">
                <div class="info">
                    <div class="business-tx">
                        <img v-lazy="userInfo.head_img" />
                    </div>
                    <div class="business-name">
                        <span class="name  f-ibm">{{ userInfo.name }}</span>
                        <span class="qualification f-ibm">
                            <i class="icon-shang indet f-ibm"></i>
                            <i class="icon-zuan indet f-ibm" v-if="userInfo.vip==2"></i>
                        </span>
                    </div>
                    <!-- bob tag 商家详情 -->
                    <div class="business-jj" style="display:none">{{ userInfo.announcement }}</div>
                    <div class="business-wx" style="display:none">
                        <div class="business-wxName" @click="copy($event, userInfo.wx)">微信：{{ userInfo.wx }}</div>
                        <div class="business-wxCode" :style="userInfo.wx_img ? '' : 'display:none !important'"><img v-lazy="userInfo.wx_img" /></div>
                    </div>
                </div>
            </div>
        </div>
        <circle-list :parmas='parmas' :isdefault='false'></circle-list>
    </div>
</template>

<script>
import { indexApi } from '@/api/module.js';
import circleList from '@/components/circlelist';
export default {
    name: 'business',
    data() {
        return {
            userInfo: {},
            parmas:{
                data:{}
            }
        };
    },
    //生命周期 - 创建完成（访问当前this实例）
    created() {},
    //生命周期 - 挂载完成（访问DOM元素）
    mounted() {
        this.requestGetUserDetailsFn(this.$route.query.id);
    },
    methods: {
        // 获取分类商家
        requestGetUserDetailsFn(id) {
            const self = this;
            indexApi
                .getUserDetails({
                    user_id: id,
                    type: 2,
                })
                .then((res) => {
                    console.log(res.data.data);
                    if (res.data.data) {
                        self.userInfo = res.data.data;
                        this.parmas = {
                            api:'/api/method/getBusinessDynamic',
                            data:{
                                size:10,
                                type:2,
                                id:id
                            }
                        };
                    }
                });
        },
        //复制
        copy(e, value) {
            e.stopPropagation();
            if (this.$isMobile) {
                let copyDom = document.createElement('input');
                copyDom.setAttribute('value', value);
                document.body.appendChild(copyDom);
                copyDom.select();
                document.execCommand('copy');
                document.body.removeChild(copyDom);
                this.$copy.show('微信号已复制，快打开微信添加吧！');
            }
        },
    },
    components: { circleList },
};
</script>

<style lang="less" scoped>
/* @import url(); 引入css类 */
.business {
    .business-briefIntroduction {
        margin: 30px auto 26px;
            position: relative;
            z-index: 999;
        .business-backImg {
            width: 100%;
            height: 240px;
            background: #000000;
            text-align: center;
            position: relative;
            z-index: 1;
            overflow: hidden;
            img {
                // width: 100%;
                height: 100%;
            }
        }
        .business-info {
            width: 100%;
            background: #ffffff;
            border: 1px solid #e5e5e5;
            text-align: center;
            padding-bottom: 35px;
            .info {
                margin-top: -64px;
                .business-tx {
                    width: 128px;
                    height: 128px;
                    background: #fbd885;
                    border: 4px solid #ffffff;
                    border-radius: 50%;
                    margin: 0 auto;
                    position: relative;
                    z-index: 2;
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }
                .business-name {
                    margin: 20px 0 28px; 
                    .name {
                        // height: 24px;
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #282828;
                        line-height: 24px;
                    }
                    .qualification {
                        .icon-shang{
                        width: 18px;
                        height: 18px;
                        background: url(../assets/pc/seller/shang@2x.png) no-repeat;
                        background-size: 100% 100%;
                        }
                        .icon-zuan{
                            width: 18px;
                            height: 18px;
                            background: url(../assets/pc/seller/zuan@2x.png) no-repeat;
                            background-size: 100% 100%;
                        }
                        .indet{
                            margin-left: 5px;
                        }
                    }
                }
                .business-jj {
                    width: 715px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #777777;
                    line-height: 24px;
                    margin: 28px auto 24px;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .business-wx {
                    width: 148px;
                    height: 30px;
                    background: #ffffff;
                    border: 1px solid #d2d2d2;
                    border-radius: 4px;
                    position: relative;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    margin: 0 auto;
                    &:hover {
                        background: #ffe6e6;
                        border: 1px solid #f3cfce;
                        text-decoration: underline;
                        .business-wxCode {
                            display: block;
                        }
                    }
                    .business-wxName {
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #666666;
                        line-height: 30px;
                        text-align: center;
                        cursor: pointer;
                    }
                    .business-wxCode {
                        display: none;
                        width: 191px;
                        height: 198px;
                        background: url('../assets/pc/round_bg@2x.png') no-repeat;
                        background-size: 100%;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        img {
                            width: 150px;
                            height: 163px;
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                        }
                    }
                }
            }
        }
    }
}

//移动端样式
@media screen and (max-width: 750px) {
    .business {
        .business-briefIntroduction {
            margin: 0 auto 8px;
            border-bottom: 8px solid #F5F5F5;
            .business-backImg {
                width: 100%;
                height: 188px;
                background: #000000;
                text-align: center;
            }
            .business-info {
                width: 100%;
                background: #ffffff;
                border: none;
                text-align: center;
                padding-bottom: 17.5px;
                .info {
                    margin-top: -32px;
                    .business-tx {
                        width: 64px;
                        height: 64px;
                        background: #fbd885;
                        border: 2px solid #ffffff;
                        border-radius: 50%;
                        margin: 0 auto;
                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }
                    }
                    .business-name {
                        margin-top: 10px;
                        margin-bottom: 17px;
                        .name {
                            // height: 19px;
                            font-size: 20px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #ff3658;
                            line-height: 24px;
                        }
                        .qualification {
                            .icon-shang,.icon-zuan{
                                width: 15px;
                                height: 15px;
                            }
                        }
                    }
                    .business-jj {
                        width: auto;
                        font-size: 12px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #777777;
                        line-height: 18px;
                        margin: 0 auto 15px;
                        padding: 0 30px;
                        box-sizing: border-box;
                    }
                    .business-wx {
                        width: 120px;
                        height: 24px;
                        background: #f02420;
                        border: none;
                        border-radius: 4px;
                        position: relative;
                        top: 0;
                        right: 0;
                        left: 0;
                        bottom: 0;
                        margin: 0 auto;
                        &:hover {
                            background: #f02420;
                            border: none;
                            text-decoration: none;
                            .business-wxCode {
                                display: none;
                            }
                        }
                        .business-wxName {
                            font-size: 12px;
                            font-family: PingFang SC;
                            font-weight: 500;
                            color: #ffffff;
                            line-height: 24px;
                        }
                        .business-wxCode {
                            display: none;
                            width: 191px;
                            height: 198px;
                            background: url('../assets/pc/round_bg@2x.png') no-repeat;
                            background-size: 100%;
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            img {
                                width: 150px;
                                height: 163px;
                                position: absolute;
                                left: 0;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                margin: auto;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
